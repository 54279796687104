



















































@import '@design';

.footer-loc {
  .location--title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
  // .r-title { }
  .location--phone-link {
    color: white;
    text-decoration: none;
  }
}
